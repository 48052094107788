import React, { useState, useRef, useEffect, useContext } from "react";
import StatusBadge from "../../UI/StatusBadge";
import { StatusBadgeTypes } from "../../../utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row } from "react-table";
import LastConfirmWarningModal from "../../UI/LastConfirmWarningModal";
import { useTranslation } from "react-i18next";
import MenuItem from "../../UI/MenuItem";
import Moment from "react-moment";
import { DEFAULT_DATE } from "../../../utils/DateUtils";
import {
  faPen,
  faTrash,
  faCheckCircle,
  faBan,
  faTurnDownRight
} from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV, faUserNurse } from "@fortawesome/pro-regular-svg-icons";
import { StaffContext } from "../../../store/StaffContext";
import i18next from "i18next";
import { language } from "../../../utils/language";
import Staff from "../../../types/Staff";
import Button from "../../UI/Button";
import { ButtonTypes } from "../../../utils/Constants";
import Dropdown, { DropdownType } from "../../UI/Dropdown";
import { ClinicTitle } from "../../../store/SearchFilterDefaultersContext";

const StaffRow: React.FC<{
  row: Row<Staff>;
  clinicsList: ClinicTitle[];
  clinicSlug: string;
  editHandler: (slug_Staff: string) => void;
  updateStatusHandler: (_status: string, _slugStaff: string) => void;
  moveStaffHandler: (staffSlug: string, newClinicSlug: string) => void;
}> = ({ row, clinicsList, clinicSlug, editHandler, updateStatusHandler, moveStaffHandler }) => {
  const { t } = useTranslation();
  const { first_name, last_name, phone_number, email, preferred_language, last_sync, status_key, is_clinic_admin, slug } = row.original;
  const [state, setState] = useState({
    showMenu: false,
    showTooltip: false,
    showDeleteModal: false,
    showDisableModal: false,
    showMoveStaffModal: false,
  });
  const parentContainerRef = useRef<HTMLDivElement>(null);
  const childContainerRef = useRef<HTMLDivElement>(null);
  const { loading } = useContext(StaffContext);

  const toggleState = (key: keyof typeof state, value: boolean) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    if (!loading) {
      setState((prev) => ({
        ...prev,
        showDisableModal: false,
        showDeleteModal: false,
        showMoveStaffModal: false,
      }));
    }
  }, [loading]);

  const renderStatusBadge = (statusKey: string) => {
    const badgeMap: Record<string, StatusBadgeTypes> = {
      AC: StatusBadgeTypes.ACTIVE,
      IC: StatusBadgeTypes.INACTIVE,
      PD: StatusBadgeTypes.PENDING,
      MV: StatusBadgeTypes.MOVED,
    };
    return <StatusBadge type={badgeMap[statusKey]} />;
  };

  const deleteHandler = (_slugStaff: string) => updateStatusHandler("DL", _slugStaff);
  const disableHandler = (_slugStaff: string) => updateStatusHandler("DB", _slugStaff);

  const actionHandler = (_status: string, _slugStaff: string, type: string) => {
    switch (_status) {
      case "AC":
        if (type === "primary") {
          toggleState("showDisableModal", true);
        } else {
          toggleState("showMoveStaffModal", true);
        }
        break;
      case "IC":
        if (type === "primary") {
          updateStatusHandler("EB", _slugStaff);
        } else {
          toggleState("showDeleteModal", true);
        }
        break;
      case "PD":
        toggleState("showDeleteModal", true);
        break;
    }
  };

  return (
    <>
      <tr className="border-t border-gray-df">
        <td
          className={`border-t td-firefox border-gray-df table__cell text-sm text-[#414141] sticky ${i18next.language === "ar" ? "right-0" : "left-0"
            }`}
        >
          {first_name}
          {is_clinic_admin && (
            <div
              className="inline relative mx-2"
              onMouseOver={() => toggleState("showTooltip", true)}
              onMouseOut={() => toggleState("showTooltip", false)}
            >
              <FontAwesomeIcon
                icon={faUserNurse}
                className="text-blue-primary"
              />
              {state.showTooltip && (
                <div className="absolute top-[-30px] left-[-32px] bg-white rounded shadow px-2 py-1 z-30 w-auto text-xs">
                  {t("clinic_admin")}
                </div>
              )}
            </div>
          )}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {last_name}
        </td>
        <td
          className={`border-t td-firefox border-gray-df table__cell text-sm text-[#414141] ${i18next.language === "ar" && "text-right"
            }`}
          dir="ltr"
        >
          {phone_number}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {email}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {language(preferred_language)}
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {last_sync.getTime() !== DEFAULT_DATE.getTime() && (
            <div>
              <Moment
                date={last_sync}
                format="DD/MM/YYYY"
                locale={i18next.language === "ar" ? "ar" : "en"}
              ></Moment>
            </div>
          )}

          {last_sync.getTime() !== DEFAULT_DATE.getTime() && (
            <div>
              <Moment
                date={last_sync}
                format="HH:mm:ss"
                locale={i18next.language === "ar" ? "ar" : "en"}
              ></Moment>
            </div>
          )}
        </td>
        <td className="td-firefox border-gray-df table__cell text-sm flex justify-between items-center">
          {renderStatusBadge(status_key)}
          <div
            className="mx-1.5"
            tabIndex={0}
            onBlur={() => toggleState("showMenu", false)}
          >
            <div
              ref={parentContainerRef}
              className="py-1 px-3 rounded cursor-pointer hover:bg-gray-f5 relative"
              onClick={() => {
                toggleState("showMenu", !state.showMenu);
                const child = childContainerRef.current!;
                const parent = parentContainerRef.current!;
                const scrollableContainer = document.getElementById(
                  "staff-table__container"
                )!;

                child.style.top = `${parent.offsetTop + 32}px`;
                let childLeft =
                  parent.offsetLeft - scrollableContainer.scrollLeft;
                if (i18next.language !== "ar") {
                  childLeft -= 148;
                }
                child.style.left = `${childLeft}px`;
              }}
            >
              <FontAwesomeIcon
                icon={faEllipsisV}
                className="text-base text-blue-primary"
              />
            </div>
            <div
              ref={childContainerRef}
              className={`absolute z-10 ${state.showMenu ? "block" : "hidden"}`}
            >
              <ul className="rounded shadow bg-white py-1 w-44">
                <MenuItem
                  titleKey={t("edit")}
                  onClick={() => {
                    editHandler(slug);
                    toggleState("showMenu", false);
                  }}
                >
                  <FontAwesomeIcon className="mx-2" icon={faPen} />
                </MenuItem>
                {status_key !== "MV" && (
                  <MenuItem
                    titleKey={(() => {
                      const title: Record<string, string> = {
                        AC: t("disable"),
                        IC: t("enable"),
                        PD: t("delete"),
                      };
                      return title[status_key];
                    })()}
                    onClick={() => {
                      actionHandler(status_key, slug, "primary");
                      toggleState("showMenu", false);
                    }}
                  >
                    {status_key === "PD" && (
                      <FontAwesomeIcon className="mx-2" icon={faTrash} />
                    )}
                    {status_key === "IC" && (
                      <FontAwesomeIcon className="mx-2" icon={faCheckCircle} />
                    )}
                    {status_key === "AC" && (
                      <FontAwesomeIcon className="mx-2" icon={faBan} />
                    )}

                  </MenuItem>
                )}
                {(status_key === "IC" || status_key === "AC") && (
                  <MenuItem
                    titleKey={(() => {
                      const title: Record<string, string> = {
                        AC: t("move_staff"),
                        IC: t("delete"),
                      };
                      return title[status_key];
                    })()}
                    onClick={() => {
                      actionHandler(status_key, slug, "secondary");
                      toggleState("showMenu", false);
                    }}
                  >
                    {status_key === "IC" && (
                      <FontAwesomeIcon className="mx-2" icon={faTrash} />
                    )}
                    {status_key === "AC" && (
                      <FontAwesomeIcon className="mx-2" icon={faTurnDownRight} />
                    )}
                  </MenuItem>
                )}
              </ul>
            </div>
          </div>

          {state.showDeleteModal && (
            <LastConfirmWarningModal
              onBackdrop={() => toggleState("showDeleteModal", false)}
              onCancel={() => toggleState("showDeleteModal", false)}
              onConfirm={() => deleteHandler(slug)}
              titleKey={"delete_staff"}
              messageKey={"delete_staff_message"}
              confirmButtonLabelKey={"yes_delete"}
            />
          )}

          {state.showDisableModal && (
            <LastConfirmWarningModal
              onBackdrop={() => toggleState("showDisableModal", false)}
              onCancel={() => toggleState("showDisableModal", false)}
              onConfirm={() => disableHandler(slug)}
              titleKey="disable_staff"
              messageKey="disable_staff_message"
              confirmButtonLabelKey="yes_disable"
            />
          )}

          {state.showMoveStaffModal && (
            <MoveStaffModal
              onBackdrop={() => toggleState("showMoveStaffModal", false)}
              onCancel={() => toggleState("showMoveStaffModal", false)}
              onConfirm={(selectedClinic) => { toggleState("showMoveStaffModal", false); moveStaffHandler(slug, selectedClinic) }}
              dropdownList={clinicsList.filter((clinic) => clinic.slug !== clinicSlug)}
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default StaffRow;

const MoveStaffModal: React.FC<{
  onCancel: () => void;
  onConfirm: (selectedClinic: string) => void;
  onBackdrop: () => void;
  dropdownList: ClinicTitle[];
}> = ({
  onCancel,
  onConfirm,
  onBackdrop,
  dropdownList,
}) => {
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const handleSave = () => {
      if (selectedItem === undefined) {
        setErrorMessage(t("new_clinic_field_is_required"));
      } else {
        setErrorMessage(null);
        setShowConfirmModal(true);
      }
    };

    const handleConfirmMove = () => {
      setShowConfirmModal(false);
      onConfirm(selectedItem!);
    };

    return (
      <div
        data-testid="logout-modal"
        className="backdrop"
        onClick={() => onBackdrop()}
      >
        <section dir={i18next.language === "ar" ? "rtl" : "ltr"}
          className="bg-white shadow-md p-6 cursor-auto w-[90%] 768:w-[520px] 480:w-[392px] rounded"
          onClick={(e) => e.stopPropagation()}
        >
          <h3 className="capitalize text-black27 text-lg font-semibold">
            {t("move_staff")}
          </h3>

          <div className="mt-10">
            <label className="text-[#414141] text-sm">{t("new_clinic")}<span className="text-red-primary mx-1.5">*</span></label>
            <Dropdown
              type={DropdownType.PRIMARY}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                setSelectedItem(option);
                setErrorMessage(null);
              }}
              keys={dropdownList.map((item) => item.slug)}
              selectedKey={selectedItem!}
              getValueByKey={(key) => {
                const matchedItem = dropdownList.find((item) => item.slug === key);
                return matchedItem ? matchedItem.name : "";
              }}
              widthClassName="w-full"
            />
          </div>

          {errorMessage && (
            <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
          )}

          <div className="flex mt-10 justify-end">
            <Button type={ButtonTypes.CANCEL} onClick={() => onCancel()} className="mx-3">
              {t("cancel")}
            </Button>
            <Button type={ButtonTypes.PRIMARY} onClick={handleSave}>
              {t("save")}
            </Button>
          </div>
        </section>

        {showConfirmModal && (
          <LastConfirmWarningModal
            onBackdrop={() => setShowConfirmModal(false)}
            onCancel={() => setShowConfirmModal(false)}
            onConfirm={handleConfirmMove}
            titleKey={t("move_staff")}
            messageKey={t("move_staff_message")}
            confirmButtonLabelKey={t("yes_move")}
          />
        )}
      </div>
    );
  };
